<template>
  <TitleWrapper
    :filter-option="true"
    title="SECT_EXAM_RES"
    tooltip-title="SECT_EXAM_RES"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiInput
          v-model.trim="filtersPayload.title"
          class="flex-1"
          title="TITLE"
          type="text"
          placeholder="TITLE"
          :filter="FILTERS_LIST.TITLE"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.generated_at"
          title="GENERATED"
          :options="RESULT_GENERATED_LIST"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.GENERATED"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.is_published"
          title="PUBLISHED"
          :options="RESULT_PUBLISHED_LIST"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.PUBLISHED"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { getCountFromObject } from '@utils/generalUtil'
import { FILTERS_LIST } from '@src/constants/filter-constants.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions, mapState } from 'vuex'
import { RESULT_GENERATED_LIST, RESULT_PUBLISHED_LIST } from '@src/constants/general-constants.js'
import filterMixins from '@/src/mixins/filter-mixins.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    TitleWrapper,
    UiSingleSelect,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      $where: {},
      RESULT_GENERATED_LIST,
      RESULT_PUBLISHED_LIST,
      FILTERS_LIST,
      filtersPayload: {
        title: '',
        generated_at: '',
        is_published: '',
        displayedColumns: [],
      },
      filtersCount: 0,
    }
  },

  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.clearFilters()
      },
    },

    currentSectionScope: {
      handler() {
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
  },
  methods: {
    getFiltersCount() {
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
