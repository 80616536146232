<template>
  <ValidationObserver @submit="addNewResult">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>
          <span v-if="!edit">Add Section Result</span>
          <span v-else>Edit Section Result</span>
        </span>
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper v-if="!currentSectionScope && !edit">
            <UiSingleSelect
              v-model="selectedSection"
              title="Section"
              :options="sectionList"
              label="title"
              reduce="id"
              class="flex-1"
              rules="required"
              @change="fetchGeneratedSubjectResult"
              @deSelect="fetchGeneratedSubjectResult"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputBox
              v-model="examData.title"
              type="text"
              name="TITLE"
              title="TITLE"
              label="TITLE"
              placeholder="TITLE"
              :class="$style.widthItems"
              class="flex-1 w-full"
              rules="required"
            />
            <InputBox
              v-model="examData.description"
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
              :class="$style.widthItems"
              class="flex-1 w-full"
            />
          </InputFieldWrapper>
          <InputFieldWrapper v-if="!edit">
            <UiSingleSelect
              v-model="examData.non_gpa_grading_id"
              title="GRADING_STRUCTURE"
              rules="required"
              label="title"
              reduce="id"
              :options="nonGPAGradingStructureList"
              :class="$style.widthItems"
              class="flex-1"
              @change="fetchGeneratedSubjectResult"
            />
          </InputFieldWrapper>
          <Divider v-if="!edit" />
          <!-- Headers -->
          <div v-if="!edit">
            <div class="flex justify-between mb-5">
              <UIButton class="px-5" @click="addNewGradeItem()">Add New</UIButton>
              <div class="text-base md:text-lg">Is Compulsory ?</div>
            </div>
            <div>
              <div
                v-for="(result, index) in resultList"
                :key="index"
                class="flex flex-col sm:flex-row gap-4 lg:gap-5"
              >
                <div class="flex justify-between text-base md:text-lg items-center w-full">
                  <InputFieldWrapper>
                    <UiSingleSelect
                      v-model="result['subject_result_id']"
                      title="Subject Exam"
                      rules="required"
                      :dynamic-title="true"
                      :name="`SUBJECT + ${index}`"
                      label="title"
                      reduce="id"
                      :options="subjectsResultList"
                      :class="$style.widthItems"
                      class="text-text-color font-roboto text-sm font-normal flex-1"
                      @change="addTotalMarks(result, subjectsResultList)"
                      @deSelect="result['total_marks'] = 0"
                    />
                  </InputFieldWrapper>
                  <div :key="result['subject_result_id']" class="text-center">
                    Total Marks : {{ result.total_marks || GENERAL_CONSTANTS.AMOUNT_ZERO }}
                  </div>
                  <InputFieldWrapper class="flex items-center gap-3 justify-end">
                    <UiCheckbox
                      v-model="result['is_compulsory']"
                      :no-error="true"
                      :model-value="result.is_compulsory"
                    />
                  </InputFieldWrapper>
                </div>
                <InputFieldWrapper>
                  <span v-if="index" class="sm:self-center self-end" @click="removeExamItem(index)">
                    <icon
                      class="icon cursor-pointer"
                      width="12"
                      height="12"
                      color="mahroon-strong"
                      icon="cross"
                    />
                  </span>
                </InputFieldWrapper>
              </div>
            </div>
            <Divider />
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="examData.min_passing_subjects"
                title="MIN_PASSING_SUBJ"
                rules="required"
                label="title"
                reduce="id"
                :options="minimunPassingSubjectsList"
                :class="$style.widthItems"
                class="text-text-color font-roboto text-sm font-normal sm:w-2/4 w-full"
              />
            </InputFieldWrapper>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              {{ edit ? 'Update' : 'Save' }}
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import Divider from '@src/components/Divider.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import icon from '@components/icons/icon.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiCheckbox from '@components/UiElements/UiCheckbox.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    InputBox,
    ValidationObserver,
    Loader,
    Divider,
    UIButton,
    icon,
    UiSingleSelect,
    UiCheckbox,
    InputFieldWrapper,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      GENERAL_CONSTANTS,
      minimunPassingSubjectsList: [],
      newExam: { subject_result_id: '', is_compulsory: false },
      examData: {
        title: '',
        description: '',
        min_passing_subjects: '',
        non_gpa_grading_id: '',
      },
      resultList: [{ subject_result_id: '', is_compulsory: false }],
      isLoading: false,
      subjectExamsList: [],
      subjectsResultList: [],
      nonGPAGradingStructureList: [],
      selectedSection: null,
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope,
      sectionList: (state) => state.section.sectionList,
    }),
  },
  watch: {
    resultList: {
      deep: true,
      handler(list) {
        this.examData.min_passing_subjects = ''
        this.minimunPassingSubjectsList = []
        let count = 0
        list.forEach((exam) => {
          if (exam.subject_result_id) count++
        })
        for (let range = 0; range <= count; range++) {
          this.minimunPassingSubjectsList.push({ title: `${range}`, id: range })
        }
      },
    },
  },
  created() {
    if (this.edit) this.setFormDate()
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      Promise.all([this.getNonGPAStructureList(), this.getGeneratedSubjectResultList()]).then(
        (response) => {
          let [structureListResponse] = response[0]
          let [examsListResponse] = response[1]
          this.nonGPAGradingStructureList = structureListResponse?.data.records || []
          this.subjectsResultList = examsListResponse?.data?.records
        },
      )
      this.filterSectionsList()
    },
    addTotalMarks(currentResult, resultList) {
      resultList.forEach((result) => {
        if (currentResult.subject_result_id === result.id)
          currentResult['total_marks'] = result.total_marks
      })
    },
    async fetchGeneratedSubjectResult() {
      this.resultList = [{ subject_result_id: '', is_compulsory: false }]
      this.subjectsResultList = []

      let queries = {
        $where: {
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'non_gpa_grading_id',
            this.examData.non_gpa_grading_id,
          ),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.selectedSection),
        },
      }
      let [response, error] = await this.getGeneratedSubjectResultList(queries)
      this.subjectsResultList = response?.data?.records
    },
    addNewGradeItem() {
      let newExam = objectDeepCopy(this.newExam)
      this.resultList.push(newExam)
    },
    removeExamItem(idx) {
      this.resultList.splice(idx, 1)
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    async addNewResult() {
      this.isLoading = true
      let payload = { exam: this.getPayload() }
      if (this.edit) payload.id = this.edit?.id
      let [res, error] = await this.addAndEditSectiontResult(payload)
      this.isLoading = false
      if (res) this.$emit('toggle', true)
      else
        this.$store.commit('toast/NEW', {
          message: error.message?.message ? error.message?.message : error.message,
          type: 'error',
        })
    },
    getPayload() {
      if (this.selectedSection) this.examData.section_id = this.selectedSection
      return {
        ...this.examData,
        ...{ section_result_subjects: this.resultList },
      }
    },
    setFormDate() {
      this.examData.title = this.edit?.title || ''
      this.examData.description = this.edit?.description || ''
      this.examData.non_gpa_grading_id = this.edit?.non_gpa_grading_id || ''
      this.examData.min_passing_subjects = this.edit?.min_passing_subjects || ''
    },
    ...mapActions('exams', [
      'getNonGPAStructureList',
      'addAndEditSectiontResult',
      'getGeneratedSubjectResultList',
    ]),
    ...mapActions('section', ['filterSectionsList']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
